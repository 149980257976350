import Router from 'next/router';
import { createSelector } from 'reselect';
import { IWedding_L10N } from '@bridebook/models/source/models/Weddings.types';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import {
  AnalyticsLocales,
  Locales,
  countryCodeToLocale,
  localeToAnalyticsLanguage,
  routingLocaleToLanguageTag,
} from 'lib/i18n/constants';
import { IApplicationState } from 'lib/types';

export const getLocalisation = (state: IApplicationState) => state?.weddings?.profile?.l10n;
const _profileLoaded = (state: IApplicationState) => state.weddings.loaded;
/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

/**
 * @deprecated [i18n] Use `Market` instead.
 */
export const getCountryCode = createSelector(
  [getLocalisation],
  (l10n): CountryCodes | null => l10n?.country || null,
);

/**
 * @deprecated [i18n] Use `Market` instead.
 */
export const getCountryCodeWithFallback = createSelector(
  [getCountryCode],
  (countryCode): CountryCodes => countryCode || CountryCodes.GB,
);

/**
 * @deprecated [i18n] Use `Market` instead.
 */
export const getWeddingLocale = createSelector(
  [getCountryCode],
  (countryCode) => countryCode && gazetteer.getMarketByCountry(countryCode)?.prefix,
);

/**
 * @deprecated [i18n] Use `Market` instead.
 */
export const getWeddingLocaleOrDefault = createSelector(
  [getWeddingLocale],
  (locale) => locale || Locales.UK,
);

/**
 * @deprecated [i18n] Get rid of this.
 */
export const getIsUK = createSelector(
  [getCountryCodeWithFallback],
  (countryCode): boolean => countryCode === CountryCodes.GB,
);

/**
 * @deprecated [i18n] Use `Market.prefix` instead.
 */

export const getIsRoutingCountryCode = createSelector(
  [getCountryCode],
  (countryCode): boolean => !!countryCodeToLocale[countryCode as keyof typeof countryCodeToLocale],
);

/**
 * @deprecated [i18n] Get rid of this.
 */
export const getIsUS = createSelector(
  [getCountryCodeWithFallback],
  (countryCode): boolean => countryCode === CountryCodes.US,
);

export const getAppAnalyticsLocale = createSelector([getLocalisation], (l10n): string => {
  const locale = (Router.locale || Locales.UK) as Locales;
  const appLanguage = localeToAnalyticsLanguage[Router.locale as Locales] || AnalyticsLocales.EN;

  return l10n?.country ? `${appLanguage}-${l10n?.country}` : routingLocaleToLanguageTag[locale];
});

export const getCurrencyCode = createSelector(
  [getLocalisation],
  (l10n: IWedding_L10N): string | null => l10n?.currency || null,
);

export const getCurrencyCodeWithFallback = createSelector(
  [getCurrencyCode],
  (currencyCode: string | null): string =>
    currencyCode ?? gazetteer.getMarketByCountry(CountryCodes.GB).currency,
);

export const getCurrencySymbol = createSelector(
  [getCurrencyCodeWithFallback],
  (currencyCode): string => Gazetteer.getCurrencySymbol(currencyCode.toUpperCase()) as string,
);

export const getCheckDirectoryLoaded = createSelector(
  [_profileLoaded],
  (profileLoaded) => profileLoaded,
);

export const getWeddingL10n = (state: IApplicationState) => state?.weddings?.profile?.l10n;

export const getUserL10n = (state: IApplicationState) => state?.users?.user?.l10n;

/**
 * This is the selector implementation of useMarket, which can be used outside of components.
 */
export const selectMarketWithFallback = (
  state: IApplicationState,
  CountryCodeFallback: CountryCodes = CountryCodes.GB,
) => {
  const marketFromQuery = Router.query.market;

  const l10nUser = getUserL10n(state);
  const l10nWedding = getWeddingL10n(state);

  /**
   * Infer the market first from the wedding country or
   * if not set, use the current router locale with a fallback
   * to the default locale.
   */
  const market = l10nWedding?.country
    ? gazetteer.getMarketByCountry(l10nWedding?.country)
    : gazetteer.getMarketByURL(
        Array.isArray(marketFromQuery) ? marketFromQuery[0] : marketFromQuery ?? undefined,
        CountryCodeFallback,
      );

  /**
   * Use the users locale if one was set.
   */
  if (l10nUser?.locale) {
    market.locale = l10nUser.locale;
  }

  /**
   * Use the wedding currency or user currency if one was set.
   */
  if (l10nWedding?.currency) {
    market.currency = l10nWedding?.currency;
  }

  /**
   * This shouldn't be enabled until we have a way for the user to override it's preferred currency.
   * Also, without forex support, this is not really useful.
   */
  // eslint-disable-next-line no-constant-condition
  // if (false && l10nUser?.currency) {
  //   market.currency = l10nUser?.currency;
  // }

  return market;
};
