import type { Slug } from '@bridebook/toolbox/src/types';

export const allowedFilters = [
  'accessories',
  'acoustic',
  'affordable',
  'aisleDecorations',
  'alcoholProvision',
  'alternative',
  'arcadeGameHire',
  'artistic',
  'asian',
  'asianCatering',
  'asianWeddingSpecialist',
  'bagpipes',
  'balloons',
  'bar',
  'barn',
  'beach',
  'beautySalons',
  'berries',
  'bespokeDesign',
  'bespokeDesigns',
  'bespokeDressmaker',
  'bespokeTailoring',
  'biodegradable',
  'blackTie',
  'blankCanvas',
  'bohemian',
  'bouncyCastleHire',
  'bouquets',
  'bridalBoutique',
  'bridesmaidDresses',
  'brownies',
  'busAndCoachHire',
  'buttercream',
  'buttonholes',
  'cakeAccessories',
  'candelabras',
  'candles',
  'candyCarts',
  'carrot',
  'casinos',
  'castle',
  'casual',
  'celebrantOfficiant',
  'centerpieces',
  'chairCovers',
  'cheeseBoardPlatter',
  'childrenEntertainment',
  'chocolate',
  'chocolateFountains',
  'choir',
  'chuppah',
  'cityHotelCityVenue',
  'classic',
  'classical',
  'cleanUp',
  'coffee',
  'completeStylingService',
  'conferenceCentre',
  'cookiesCream',
  'countryHouseManorHouse',
  'cruiseBoatYacht',
  'cufflinks',
  'cupcakes',
  'dancefloor',
  'dancefloorHire',
  'dancers',
  'delivery',
  'digitalEditingAirbrushing',
  'diningFurnitureHire',
  'disco',
  'discoLighting',
  'divideAndRevealCurtain',
  'dj',
  'donuts',
  'dramatic',
  'dressDesigner',
  'droneFootage',
  'drums',
  'editorial',
  'engagementRings',
  'ensembleGroup',
  'externalCateringPermitted',
  'fireworks',
  'flooring',
  'flowerConfetti',
  'flowerGirlBaskets',
  'foamFree',
  'fondant',
  'formal',
  'freeSamples',
  'fruitCake',
  'fullServicePlanning',
  'gardenOutdoor',
  'generator',
  'gifts',
  'golfCourse',
  'greatViews',
  'greeneryHire',
  'guitar',
  'hairdressing',
  'hairExtensions',
  'halalCatering',
  'handCalligraphy',
  'handmade',
  'harp',
  'hasBridebookOffer',
  'hasDiscounts',
  'hasGift',
  'hasLateAvailability',
  'hasQualityPricing',
  'hasWeddingFair',
  'hatsFascinators',
  'heating',
  'helicopter',
  'historic',
  'horseAndCarriage',
  'hotel',
  'inHouseCatering',
  'intimate',
  'jazz',
  'jazzBand',
  'jewishWeddingSpecialist',
  'karaoke',
  'kilts',
  'kosherCatering',
  'laserHairRemoval',
  'lastMinuteOrders',
  'latinSalsa',
  'lemon',
  'lighting',
  'linenProvided',
  'lingerie',
  'localSourcing',
  'loungeSuits',
  'lovelyGrounds',
  'luxury',
  'luxuryToilets',
  'macarons',
  'magicians',
  'makeUpArtists',
  'mobileServices',
  'modern',
  'morningSuits',
  'museumAttraction',
  'muslimWeddingSpecialist',
  'nailArtists',
  'naked',
  'natural',
  'offSitePreparation',
  'onlineProofing',
  'onTheDayWeddingCoordination',
  'organ',
  'otherFlavour',
  'otherWeddingSpecialist',
  'ourVenue',
  'outdoor',
  'peakedHatMarquees',
  'personalTrainers',
  'petFriendly',
  'photoAndVideoBooth',
  'piano',
  'placeOfWorship',
  'popRock',
  'portaloos',
  'pricingAffordable',
  'pricingLuxury',
  'pricingModerate',
  'pricingSuperLuxury',
  'printing',
  'pubRestaurant',
  'raspberryWhiteChocolate',
  'recycledPaperOptions',
  'redVelvet',
  'reportageDocumentary',
  'resort',
  'romantic',
  'rustic',
  'saltedCaramel',
  'sameDayHighlightFilm',
  'seasonalSourcing',
  'semiNaked',
  'setUp',
  'sheetCakes',
  'shootWithFilm',
  'soloMusician',
  'soundPaHire',
  'speechWriter',
  'sportingVenueStadium',
  'sportsCars',
  'staffProvided',
  'starlightLining',
  'statelyHome',
  'stayThroughDancing',
  'stretchedMarquee',
  'stretchLimos',
  'strings',
  'stylish',
  'suitHire',
  'swingJive',
  'tablePlan',
  'tablesAndChairsProvided',
  'tablescaping',
  'tablewareProvided',
  'tanning',
  'tastings',
  'tattooCoverUpMakeUp',
  'threading',
  'tieredCakes',
  'tipisWigwams',
  'toastMasterOfCeremony',
  'townHallRegistryOffice',
  'toxicChemicalFree',
  'traditionalCanvasPoleMarquees',
  'traditionalClassic',
  'treatsAndFavours',
  'unique',
  'uniqueVenueType',
  'unusual',
  'urban',
  'vanilla',
  'videographyAvailable',
  'vintage',
  'vintageClassicCars',
  'violin',
  'vocalCoach',
  'warehouseFactory',
  'waxing',
  'weddingAlbums',
  'weddingArches',
  'weddingCake',
  'weddingDresses',
  'weddingHighlightsFilm',
  'weddingInvitations',
  'weddingRings',
  'whimsical',
  'isCurated',
] as const;

export const allowedGroupFilters = [
  'additionalServicesSection',
  'bestOfBritain',
  'businessTypeSection',
  'dietaryOptionsSection',
  'exclusiveUse',
  'floristEcoOptionsSection',
  'floristStylesSection',
  'foodDrinkSection',
  'foodTypeSection',
  'generalServicesSection',
  'hasAccommodation',
  'instrumentsSection',
  'linkedSuppliers',
  'musicStyleSection',
  'overview',
  'overviewSection',
  'photographyStyleSection',
  'priceSection',
  'productsServicesSection',
  'specialistWeddingSection',
  'specialOffers',
  'tags',
  'upcomingEvents',
  'venueFeatures',
  'venueStyle',
  'venueType',
  'weddingLicence',
  'icingOptionsSection',
  'flavourOptionsSection',
  'destinationWeddingsSection',
] as const;

type Fields = (typeof allowedFilters)[number];

type GroupField = (typeof allowedGroupFilters)[number];

export interface StrangeSectionType {
  field: GroupField;
  section?: string;
}

export interface SectionType {
  name?: string;
  field: GroupField;
  showAllFields: boolean;
  fieldsToShow: (Fields | StrangeSectionType)[];
}

interface Section {
  sections: Array<SectionType>;
}

export interface FilterSectionsType extends Record<Slug, Section> {}

const FilterSections: FilterSectionsType = {
  beauty: {
    sections: [
      {
        name: 'beautySalons',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'beautySalons',
          'hairdressing',
          'hairExtensions',
          'nailArtists',
          'makeUpArtists',
          'waxing',
          'threading',
          'tanning',
          'tattooCoverUpMakeUp',
          'personalTrainers',
          'laserHairRemoval',
          'mobileServices',
        ],
      },
    ],
  },
  cakes: {
    sections: [
      {
        name: 'tieredCakes',
        field: 'generalServicesSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'vegan',
        field: 'dietaryOptionsSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'icing',
        field: 'icingOptionsSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'flavours',
        field: 'flavourOptionsSection',
        showAllFields: true,
        fieldsToShow: [],
      },
    ],
  },
  catering: {
    sections: [
      {
        name: 'westernEuropeanCuisine',
        field: 'foodTypeSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'weddingCake',
        field: 'generalServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'weddingCake',
          'alcoholProvision',
          'staffProvided',
          'tablewareProvided',
          'tablesAndChairsProvided',
          'linenProvided',
          'tastings',
          'lastMinuteOrders',
          'offSitePreparation',
        ],
      },
      {
        name: 'vegetarian',
        field: 'dietaryOptionsSection',
        showAllFields: true,
        fieldsToShow: [],
      },
    ],
  },
  decoration: {
    sections: [
      {
        name: 'completeStylingService',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'completeStylingService',
          'balloons',
          'candles',
          'candelabras',
          'chairCovers',
          'diningFurnitureHire',
          'greeneryHire',
          'arcadeGameHire',
          'dancefloorHire',
          'soundPaHire',
          'portaloos',
          'luxuryToilets',
          'setUp',
          'cleanUp',
          'lastMinuteOrders',
        ],
      },
    ],
  },
  dress: {
    sections: [
      {
        name: 'bridalBoutique',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'bridalBoutique',
          'dressDesigner',
          'bespokeDressmaker',
          'weddingDresses',
          'bridesmaidDresses',
          'lingerie',
          'hatsFascinators',
          'accessories',
        ],
      },
    ],
  },
  entertainment: {
    sections: [
      {
        name: 'bouncyCastleHire',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'bouncyCastleHire',
          'casinos',
          'chocolateFountains',
          'fireworks',
          'photoAndVideoBooth',
          'magicians',
          'candyCarts',
          'dancers',
          'childrenEntertainment',
        ],
      },
    ],
  },
  florist: {
    sections: [
      {
        name: 'bouquets',
        field: 'productsServicesSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'styles',
        field: 'floristStylesSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'ecoFriendlyPractices',
        field: 'floristEcoOptionsSection',
        showAllFields: true,
        fieldsToShow: [],
      },
    ],
  },
  group: {
    sections: [],
  },
  jewellery: {
    sections: [
      {
        name: 'weddingRings',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'weddingRings',
          'engagementRings',
          'bespokeDesigns',
          'cufflinks',
          'accessories',
          'gifts',
        ],
      },
    ],
  },
  marquee: {
    sections: [
      {
        name: 'traditionalCanvasPoleMarquees',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'traditionalCanvasPoleMarquees',
          'stretchedMarquee',
          'peakedHatMarquees',
          'tipisWigwams',
          'starlightLining',
          'divideAndRevealCurtain',
          'flooring',
          'heating',
          'lighting',
          'dancefloor',
          'generator',
          'lastMinuteOrders',
        ],
      },
    ],
  },
  menswear: {
    sections: [
      {
        name: 'bespokeTailoring',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'bespokeTailoring',
          'loungeSuits',
          'morningSuits',
          'blackTie',
          'accessories',
          'suitHire',
          'kilts',
          'lastMinuteOrders',
        ],
      },
    ],
  },
  music: {
    sections: [
      {
        name: 'disco',
        field: 'businessTypeSection',
        showAllFields: false,
        fieldsToShow: ['disco', 'jazzBand', 'ensembleGroup', 'soloMusician', 'choir', 'dj'],
      },
      {
        name: 'popRock',
        field: 'musicStyleSection',
        showAllFields: false,
        fieldsToShow: ['popRock', 'classical', 'acoustic', 'jazz', 'swingJive', 'latinSalsa'],
      },
      {
        name: 'harp',
        field: 'instrumentsSection',
        showAllFields: false,
        fieldsToShow: [
          'guitar',
          'piano',
          'drums',
          'violin',
          'strings',
          'harp',
          'bagpipes',
          'organ',
        ],
      },
      {
        name: 'discoLighting',
        field: 'additionalServicesSection',
        showAllFields: false,
        fieldsToShow: ['discoLighting', 'dancefloorHire', 'karaoke'],
      },
    ],
  },
  photo: {
    sections: [
      {
        name: 'photographyStyleSection',
        field: 'photographyStyleSection',
        showAllFields: false,
        fieldsToShow: [
          'traditionalClassic',
          'reportageDocumentary',
          'modern',
          'natural',
          'artistic',
          'editorial',
          'dramatic',
          'alternative',
        ],
      },
      {
        name: 'priceSection',
        field: 'priceSection',
        showAllFields: false,
        fieldsToShow: [
          'pricingAffordable',
          'pricingModerate',
          'pricingLuxury',
          'pricingSuperLuxury',
        ],
      },
      {
        name: 'digitalEditingAirbrushing',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'digitalEditingAirbrushing',
          'onlineProofing',
          'shootWithFilm',
          'videographyAvailable',
          'weddingAlbums',
          'stayThroughDancing',
          'lastMinuteOrders',
        ],
      },
      {
        name: 'linkedSuppliers',
        field: 'linkedSuppliers',
        showAllFields: false,
        fieldsToShow: ['ourVenue'],
      },
      {
        name: 'specialOffers',
        field: 'specialOffers',
        showAllFields: false,
        fieldsToShow: ['hasLateAvailability'],
      },
    ],
  },
  planners: {
    sections: [
      {
        name: 'fullServicePlanning',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'fullServicePlanning',
          'onTheDayWeddingCoordination',
          'toastMasterOfCeremony',
          'celebrantOfficiant',
          'speechWriter',
          'vocalCoach',
        ],
      },
    ],
  },
  stationery: {
    sections: [
      {
        name: 'bespokeDesign',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'bespokeDesign',
          'printing',
          'handmade',
          'weddingInvitations',
          'tablePlan',
          'recycledPaperOptions',
          'handCalligraphy',
          'freeSamples',
          'lastMinuteOrders',
        ],
      },
    ],
  },
  transport: {
    sections: [
      {
        name: 'vintageClassicCars',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'vintageClassicCars',
          'horseAndCarriage',
          'sportsCars',
          'stretchLimos',
          'helicopter',
          'busAndCoachHire',
        ],
      },
    ],
  },
  venue: {
    sections: [
      {
        name: 'overviewSection',
        field: 'overviewSection',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'venueType',
        field: 'venueType',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'venueStyle',
        field: 'venueStyle',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'venueFeatures',
        field: 'venueFeatures',
        showAllFields: true,
        fieldsToShow: [],
      },
      {
        name: 'specialOffers',
        field: 'specialOffers',
        showAllFields: false,
        fieldsToShow: ['hasDiscounts', 'hasBridebookOffer', 'hasGift', 'hasLateAvailability'],
      },
      {
        name: 'foodDrinkSection',
        field: 'foodDrinkSection',
        showAllFields: false,
        fieldsToShow: [
          'asianCatering',
          'bar',
          'externalCateringPermitted',
          'halalCatering',
          'inHouseCatering',
          'kosherCatering',
        ],
      },
      {
        name: 'tags',
        field: 'tags',
        showAllFields: false,
        fieldsToShow: [
          'asianWeddingSpecialist',
          'jewishWeddingSpecialist',
          'muslimWeddingSpecialist',
          'otherWeddingSpecialist',
        ],
      },
      {
        name: 'priceSection',
        field: 'priceSection',
        showAllFields: false,
        fieldsToShow: [
          'pricingAffordable',
          'pricingModerate',
          'pricingLuxury',
          'pricingSuperLuxury',
        ],
      },
      {
        name: 'upcomingEvents',
        field: 'upcomingEvents',
        showAllFields: false,
        fieldsToShow: ['hasWeddingFair'],
      },
      {
        name: 'destinationWeddingsSection',
        field: 'destinationWeddingsSection',
        showAllFields: false,
        fieldsToShow: ['isCurated'],
      },
    ],
  },
  video: {
    sections: [
      {
        name: 'weddingHighlightsFilm',
        field: 'productsServicesSection',
        showAllFields: false,
        fieldsToShow: [
          'weddingHighlightsFilm',
          'sameDayHighlightFilm',
          'droneFootage',
          'stayThroughDancing',
          'lastMinuteOrders',
        ],
      },
    ],
  },
};

export default FilterSections;
