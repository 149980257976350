import { ILocalisationProps, getLocalisation } from 'lib/analytics-utils';
import { IApplicationState } from 'lib/types';

interface IServerSideTrackProps extends ILocalisationProps {
  anonymousId: string;
  isAnonymous: boolean;
  mobileApp: boolean;
  sessionId: string;
  userId: string;
  weddingId: string;
  previousPath: string;
  pageTitle: string;
  pathname: string;
}

/**
 * Get props required for server side analytics
 *
 * @function getServerSideAnalyticsProps
 * @param {Object} - getState
 * @returns {Object} - Object with props
 */
export const getServerSideAnalyticsProps = (state: IApplicationState): IServerSideTrackProps => {
  const {
    users: { user },
    app: {
      previousPath,
      pathname,
      device: { isCordova, sessionId },
    },
    weddings: {
      profile: { id: weddingId },
    },
  } = state;

  const pageTitle: string = window.document.title || '';

  return {
    userId: (user && user.id) || '',
    weddingId,
    anonymousId: (analytics && analytics.user && analytics.user().anonymousId()) || '',
    isAnonymous: false,
    mobileApp: isCordova,
    sessionId: sessionId || '',
    previousPath,
    pageTitle,
    pathname,
    ...getLocalisation(state),
  };
};

export default getServerSideAnalyticsProps;
