import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { IApplicationState } from 'lib/types';

const _abtests = (state: IApplicationState) => state.ui.abtests;
const _abtestsLoaded = (state: IApplicationState) => state.ui.abtestsLoaded;

/**
 * Returns test variant if activated or a fallback to default
 */
export const getTestVariant = createCachedSelector(
  _abtests,
  (_: IApplicationState, id: string) => id,
  (abtests, id): CohortNames => abtests[id] || 'control',
)((_, id) => id);

/**
 * Returns true, if the test has a variant in store
 */
export const getIsTestActivated = createCachedSelector(
  _abtests,
  (_: IApplicationState, id: string) => id,
  (abtests, id) => Object.keys(abtests).includes(id),
)((_, id) => id);

export const getAreABTestsLoaded = createSelector(_abtestsLoaded, (abtestsLoaded) => abtestsLoaded);
