import {
  changedSearchCategoryAnalytics,
  changedSearchLocationAnalytics,
  changedSearchLocationAnalyticsDebounced,
  clickMapMarker,
  clickedOnFilteredSearchTileAnalytics,
  fetchSearchStart,
  fetchSearchSuccess,
  fetchSearchSuggestions,
  forceToggleQuickFilter,
  highlightMapMarker,
  mapMarkerClickedAnalytics,
  mapMoved,
  resetSearchFilters,
  setLastRowPosition,
  setPerformedSearch,
  setPopularVenueTypes,
  setPreset,
  setSearchLocationAutocompleteText,
  setSearchLocationEnd,
  setSearchLocationUnknown,
  setSearchSource,
  setSearchbarFormField,
  setSessionSuppliersRedirectTarget,
  setShowAllSupplierTypes,
  suggestionSuccess,
  toggleMapView,
  toggleSearchFilterAccordion,
  toggleSearchModal,
  triggerClickedSuppliersPageAnalytics,
  updateFiltersCount,
  updateSearchCategory,
  updateSearchFilters,
} from 'lib/search/actions';
import { ISearchSuccessAnalyticsCustomData } from 'lib/search/analytics';
import { TSearchResponse } from 'lib/search/types';

export enum SearchActionTypes {
  FETCH_SEARCH_START = '[Search] Fetch search start',
  FETCH_SEARCH_SUCCESS = '[Search] Fetch search success',
  TOGGLE_FILTERS = '[Search] Toggle filters',
  RESET_SEARCH_FILTERS_TO_INITIAL = '[Search] Reset search filters to initial',
  SAVE_LOCATION_DATA = '[Search] Save location data',
  RESET_LOCATION_DATA = '[Search] Reset location data',
  FETCH_SEARCH_SUGGESTIONS = '[Search] Fetch search suggestions',
  FETCH_SEARCH_SUGGESTIONS_SUCCESS = '[Search] Fetch search suggestions success',
  UPDATE_SEARCH_CATEGORY = '[Search] Update search category',
  SET_SEARCHBAR_FORM_FIELD = '[Search] Set searchbar form field',
  RESET_SEARCH_FILTERS = '[Search] Reset search filters',
  CLEAR_ALL_SEARCH = '[Search] Clear all search',
  UPDATE_SEARCH_FILTERS = '[Search] Update search filters',
  UPDATE_SEARCH_URL = '[Search] Update search url',
  SEARCH_LOAD_MORE = '[Search] Search load more',
  UPDATE_FILTERS_COUNT = '[Search] Update filters count',
  TOGGLE_SEARCH_BY_NAME_MODAL = '[Search] Toggle search by name modal',
  TOGGLE_SEARCH_BY_LOCATION_MODAL = '[Search] Toggle search by location modal',
  SET_SEARCH_MODAL_OPTIONS = '[Search] Set search modal options',
  RESET_SEARCH_MODAL_OPTIONS = '[Search] Reset search modal options',
  TOGGLE_SEARCH_FILTER_ACCORDION = '[Search] Toggle search filter accordion',
  SEARCH_SET_LAST_ROW_POSITION = '[Search] Search set last row position',
  RESET_SEARCH_SUGGESTIONS = '[Search] Reset search suggestions',
  SET_PERFORMED_SEARCH = '[Search] Set performed search',
  USED_SEARCH_CAROUSEL_ANALYTICS = '[Analytics] [Search] Used search carousel',
  FETCH_SEARCH_SUCCESS_ANALYTICS = '[Analytics] [Search] Fetch search success',
  TOGGLED_SEARCH_FILTERS_ANALYTICS = '[Analytics] [Search] Toggled search filters',
  FETCH_SEARCH_SUGGESTIONS_ANALYTICS = '[Analytics] [Search] Fetch search suggestions',
  RESET_SEARCH_FILTERS_ANALYTICS = '[Analytics] [Search] Reset search filters',
  FETCH_SEARCH_SUGGESTIONS_ANALYTICS_DEBOUNCED = '[Analytics] [Search] Fetch search suggestions analytics debounced',
  TOGGLED_SEARCH_TYPE_ANALYTICS = '[Analytics] [Search] Toggled search type',
  UPDATE_SEARCH_FILTERS_ANALYTICS = '[Analytics] [Search] Update search filters',
  SEARCH_LOAD_MORE_ANALYTICS = '[Analytics] [Search] Search load more',
  SEARCH_SORT_CHANGE_ANALYTICS = '[Analytics] [Search] Search sort change',
  TOGGLE_SEARCH_BAR_ANALYTICS = '[Analytics] [Search] Toggle search bar',
  SET_SEARCH_SOURCE = '[Analytics] [Search] Set search source',
  UPDATE_WEDDING_LOCATION_ON_SEARCH = '[Search] Update Wedding location on search',
  MAP_CLEAR_HIGHLIGHTED_MARKER = '[Search] Map clear highlighted markers',
  MAP_CLEAR_CLICKED_MARKER = '[Search] Map clear clicked markers',
  MAP_MARKER_HIGHLIGHTED = '[Search] Map marker highlighted',
  MAP_MARKER_CLICKED = '[Search] Map marker clicked',
  MAP_MARKER_CLICKED_ANALYTICS = '[Analytics] [Search] Map marker clicked analytics',
  MAP_MOVED = '[Search] Map moved',
  TOGGLE_MAP_VIEW = '[Search] Toggle map view',
  VIEWED_INJECTED_SNIPPET = '[Analytics] [Search] Viewed injected snippets',
  CHANGED_SEARCH_CATEGORY_ANALYTICS = '[Analytics] [Search] Changed search category',
  CHANGED_SEARCH_LOCATION_ANALYTICS = '[Analytics] [Search] Changed search location',
  CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED = '[Analytics] [Search] Changed search location debounced',
  USED_TAB_NAVIGATION_ON_SEARCH_LANDING_PAGE_ANALYTICS = '[Analytics] [Search] Used tab navigation on search landing page',
  CLICKED_ON_FILTERED_SEARCH_TILE_ANALYTICS = '[Analytics] [Search] Clicked on filtered search tile',
  INTERACTED_WITH_INJECTED_SNIPPET = '[Analytics] [Search] Interacted with injected snippets',
  STARTED_QUIZ = '[Analytics] [Search] User started quiz',
  NO_RESULTS_QUIZ = '[Analytics] [Search] Loaded no quiz match page',
  CHOSE_QUIZ_FILTERS = '[Analytics] [Search] User chose quiz filters',
  WENT_TO_NEXT_QUIZ_STEP = '[Analytics] [Search] User went to next quiz page',
  REVEALED_QUIZ_MATCHES = '[Analytics] [Search] User revealed quiz matches',
  EXITED_QUIZ = '[Analytics] [Search] User exited quiz',
  LOADED_SEARCH_PREFERENCE_PAGE = '[Analytics] [Search] Loaded search preference page',
  CLICKED_FIND_MY_MATCH = '[Analytics] [Search] Clicked find my match',
  CLICKED_SEARCH_MYSELF = '[Analytics] [Search] Clicked search myself',
  USER_WENT_TO_A_PREVIOUS_QUIZ_PAGE = '[Analytics] [Search] User went to a previous quiz page',
  TAB_NAVIGATION_SUPPLIERS = '[Analytics] [Search] Tab navigation suppliers',
  SET_SEARCH_LOCATION_START = '[Search] Set search location start',
  SET_SEARCH_LOCATION_END = '[Search] Set search location end',
  SET_SEARCH_LOCATION_UNKNOWN = '[Search] Set search location unknown',
  SEARCH_BY_SEARCH_LOCATION_START = '[Search] Search by search location start',
  SEARCH_BY_SEARCH_LOCATION_END = '[Search] Search by search location end',
  SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT = '[Search] Set search location autocomplete text',
  INIT_SEARCH_LOCATION = '[Search] Init search location',
  SET_POPULAR_VENUE_TYPES = '[Search] Set popular venue types',
  SET_SESSION_RECENT_SEARCH = '[Search] Set session recent search',
  SET_GLOBAL_LOCATIONS_AS_SESSION_SUPPLIER_TARGET = '[Search] Set global locations as session supplier target',
  SET_SEARCH_LANDING_AS_SESSION_SUPPLIER_TARGET = '[Search] Set search landing as session supplier target',
  SET_SESSION_SUPPLIERS_REDIRECT_TARGET = '[Search] Set session suppliers redirect target',
  VIEWED_BANNER_ANALYTICS = '[Analytics] [Search] Viewed banner',
  CLICKED_BANNER_ANALYTICS = '[Analytics] [Search] Clicked banner',
  CLICKED_REGISTER_INTEREST_BANNER_ANALYTICS = '[Analytics] [Search] Clicked register interest banner',
  LOADED_RECOMMENDED_SUPPLIER_PAGE = '[Analytics] [Search] Loaded recommended supplier page',
  CLICKED_SHOW_ME_SUPPLIERS = '[Analytics] [Search] Clicked show me suppliers',
  CHANGED_CATEGORY_SELECTION = '[Analytics] [Search] Changed category selection',
  CLICKED_SEE_ALL_SUPPLIERS = '[Analytics] [Search] Clicked see all suppliers',
  CLICKED_SEARCH_MODAL = '[Analytics] [Search] Clicked search input to display search modal',
  SET_PRESET = '[Search] Set collection ID',
  RESET_SEARCH_CONDITIONS = '[Search] Reset search conditions (filters, map coords etc.)',
  FORCE_TOGGLE_QUICK_FILTER = '[Search] Force toggle quick filter',
  INTERACTED_WITH_AD_ANALYTICS = '[Analytics] Interacted with ad',
}

export type IFetchSearchSuccessAction = ReturnType<typeof fetchSearchSuccess>;
export type IFetchSuggestionsSuccessAction = ReturnType<typeof suggestionSuccess>;
export type IUpdateSearchCategoryAction = ReturnType<typeof updateSearchCategory>;
export type IFetchSearchStart = ReturnType<typeof fetchSearchStart>;
export type IUpdateFiltersCountAction = ReturnType<ReturnType<typeof updateFiltersCount>>;
export type IResetSearchFilters = ReturnType<ReturnType<typeof resetSearchFilters>>;
export type IUpdateSearchFiltersAction = ReturnType<ReturnType<typeof updateSearchFilters>>;
export type IToggleSearchFilterAccordionAction = ReturnType<
  ReturnType<typeof toggleSearchFilterAccordion>
>;
export type ISetSearchBarFormFieldAction = ReturnType<typeof setSearchbarFormField>;
export type ISetLastRowPositionAction = ReturnType<typeof setLastRowPosition>;
export type ISearchSourceAction = ReturnType<typeof setSearchSource>;
export type ISetPerformedSearchAction = ReturnType<typeof setPerformedSearch>;
export type IFetchSearchSuggestionsAction = ReturnType<typeof fetchSearchSuggestions>;
export type IHighlightMapMarker = ReturnType<typeof highlightMapMarker>;
export type IClickMapMarker = ReturnType<typeof clickMapMarker>;
export type IMapMarkedClickedAnalytics = ReturnType<typeof mapMarkerClickedAnalytics>;
export type IMapMoved = ReturnType<typeof mapMoved>;
export type IToggleMapView = ReturnType<typeof toggleMapView>;
export type ISetPopularVenueTypes = ReturnType<typeof setPopularVenueTypes>;
export type ISetLocationEndAction = ReturnType<typeof setSearchLocationEnd>;
export type IShowAllSupplierTypes = ReturnType<typeof setShowAllSupplierTypes>;
export type IToggleSearchModal = ReturnType<typeof toggleSearchModal>;
export type ISetPreset = ReturnType<typeof setPreset>;
export type IForceToggleQuickFilter = ReturnType<typeof forceToggleQuickFilter>;

export type ReturnTypeAction<Type, Func extends (...args: any) => any> = Omit<
  ReturnType<Func>,
  'type'
> & {
  type: Type;
};

export type IChangedSearchCategoryAnalytics = ReturnTypeAction<
  SearchActionTypes.CHANGED_SEARCH_CATEGORY_ANALYTICS,
  typeof changedSearchCategoryAnalytics
>;

export type IChangedSearchLocationAnalytics = ReturnTypeAction<
  SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS,
  typeof changedSearchLocationAnalytics
>;

export type IChangedSearchLocationAnalyticsDebounced = ReturnTypeAction<
  SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED,
  typeof changedSearchLocationAnalyticsDebounced
>;

export type IClickedOnFilteredSearchTileAnalytics = ReturnTypeAction<
  SearchActionTypes.CLICKED_ON_FILTERED_SEARCH_TILE_ANALYTICS,
  typeof clickedOnFilteredSearchTileAnalytics
>;
export type ISetSearchLocationAutocompleteText = ReturnTypeAction<
  SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT,
  typeof setSearchLocationAutocompleteText
>;

export type ISetSessionSuppliersRedirectTarget = ReturnTypeAction<
  SearchActionTypes.SET_SESSION_SUPPLIERS_REDIRECT_TARGET,
  typeof setSessionSuppliersRedirectTarget
>;

export type ISearchTriggerClickedSuppliersPageAnalytics = ReturnTypeAction<
  SearchActionTypes.TAB_NAVIGATION_SUPPLIERS,
  typeof triggerClickedSuppliersPageAnalytics
>;

export type ISetSearchLocationUnknown = ReturnTypeAction<
  SearchActionTypes.SET_SEARCH_LOCATION_UNKNOWN,
  typeof setSearchLocationUnknown
>;

export type IFetchSearchSuccessAnalytics = {
  type: SearchActionTypes.FETCH_SEARCH_SUCCESS_ANALYTICS;
  payload: {
    customData?: ISearchSuccessAnalyticsCustomData;
    searchResponse?: TSearchResponse;
  };
};
