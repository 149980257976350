import { useEffect } from 'react';
import { useABTestsFetch } from 'app-shared/lib/ab-tests/use-ab-tests-fetch';
import { setABTestsLoaded } from 'lib/ui/actions';
import { useDispatch } from 'lib/utils';

/**
 * Fetches all AB Tests configs from Firestore
 */
export const useABTestsLoggedIn = () => {
  const dispatch = useDispatch();

  const { abTests, abTestsLoaded } = useABTestsFetch();

  useEffect(() => {
    if (!abTestsLoaded) {
      dispatch(setABTestsLoaded(true));
    }
  }, [dispatch, abTestsLoaded]);

  return {
    abTests,
    abTestsLoaded,
  };
};
