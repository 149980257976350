import { Locale, format, setMonth } from 'date-fns';
import { getSeasonInMonths } from '@bridebook/toolbox/src/datepicker/get-season-in-months';
import { Month, Season } from '@bridebook/toolbox/src/datepicker/types';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { getI18n } from 'lib/i18n/getI18n';

export type PriceFilterWeekDay = 'monWed' | 'thu' | 'fri' | 'sat' | 'sun';

export const priceFilterSeasonsArray: Season[] = ['spring', 'summer', 'autumn', 'winter'];
export const priceFilterWeekDaysArray: PriceFilterWeekDay[] = [
  'monWed',
  'thu',
  'fri',
  'sat',
  'sun',
];

type SeasonOption = {
  translationSeason: string;
  translationMonthDuration: string;
};

const createSeasonOption = (season: Season, market: Market, locale: Locale): SeasonOption => {
  const i18n = getI18n();

  const seasonTranslations: Record<Season, string> = {
    spring: i18n.t('search:season.spring'),
    summer: i18n.t('search:season.summer'),
    autumn: i18n.t('search:season.autumn'),
    winter: i18n.t('search:season.winter'),
  };

  const seasonInMonths = getSeasonInMonths(season, market);

  return {
    translationSeason: seasonTranslations[season],
    translationMonthDuration: `(${format(setMonth(new Date(), seasonInMonths[0]), 'LLL', {
      locale,
    })} - ${format(setMonth(new Date(), seasonInMonths[seasonInMonths.length - 1]), 'LLL', {
      locale,
    })})`,
  };
};

export const getPriceFilterSeasonTranslations = (market: Market, locale: Locale) =>
  priceFilterSeasonsArray.reduce(
    (acc, season) => ({
      ...acc,
      [season]: createSeasonOption(season, market, locale),
    }),
    {} as Record<Season, SeasonOption>,
  );

export const getPriceFilterSeasonMapping = (market: Market) =>
  priceFilterSeasonsArray.reduce(
    (acc, season) => ({
      ...acc,
      [season]: getSeasonInMonths(season, market),
    }),
    {} as Record<Season, Month[]>,
  );

export const priceFilterWeekDayMapping: Record<PriceFilterWeekDay, string[]> = {
  monWed: ['mon', 'tue', 'wed'],
  thu: ['thu'],
  fri: ['fri'],
  sat: ['sat'],
  sun: ['sun'],
};
