import difference from 'lodash/difference';
import Router from 'next/router';
import { getSearchUrlWithFilters } from '@bridebook/toolbox';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { TGetSearchUrlWithFiltersParams } from '@bridebook/toolbox/src/getSearchUrlWithFilters';
import { countriesExcludedTypes } from '@bridebook/toolbox/src/supplier/constants';
import type { Slug } from '@bridebook/toolbox/src/types';
import {
  TSearchSupplierTypesRequestBody,
  TSearchSupplierTypesResponse,
} from 'pages/api/search/supplier-types';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { selectMarketWithFallback } from 'lib/i18n/selectors';
import {
  getPopularVenueTypesTexts,
  orderSupplierTypesByBookingPriority,
} from 'lib/search-landing/utils';
import { setPopularVenueTypes, setSearchSource, toggleMapView } from 'lib/search/actions';
import { getIsMapView } from 'lib/search/selectors';
import { extractAreaParams, getDefaultSearchArea, isSearchResultsPage } from 'lib/search/utils';
import { navigateToRecentSearch } from 'lib/search/utils/navigate-to-recent-search';
import { getBookedSupplierCategories } from 'lib/shortlist/selectors';
import { recentLocationSearchesStorage } from 'lib/storage-manager/hooks';
import { IDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { noopAction } from 'lib/utils';
import { assertState } from 'lib/utils/assertState';
import { POPULAR_VENUE_TYPES } from './constants';

/**
 * The below function should only be called for logged-in users
 * as the global locations page is not available for logged-out
 * */
const navigateToGlobalLocations = () => () => {
  const asyncAction = async () => {
    await Router.push(UrlHelper.globalLocations);
  };
  asyncAction();

  return noopAction();
};

/*
When clicking on “Venues and Suppliers” on desktop or the “Explore” tab on mobile
- If there are recent searches, we open the recent search (from local storage)
- If there are no recent searches, we open the search for the wedding location
    - The order of suppliers is venue (until booked), then photographer, then florists (for markets with full directory)
    - The order of suppliers is venue (even if booked, for markets with venue directory)
 */
export const navigateToVenuesAndSuppliers =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();

      const isMapView = getIsMapView(state);

      // if current page is search results and map view is open
      // simply close map and stay in list view
      if (isSearchResultsPage(Router.asPath) && isMapView) {
        return dispatch(toggleMapView(false));
      }

      const recentSearches = recentLocationSearchesStorage.get();
      if (recentSearches && recentSearches.length > 0) {
        const [recentSearch, ...restRecentSearches] = recentSearches;
        recentLocationSearchesStorage.set(restRecentSearches);
        await navigateToRecentSearch(recentSearch);
      } else {
        dispatch(setSearchSource('navigationBar'));

        const market = selectMarketWithFallback(state);
        if (!market.hasAnyDirectory) {
          dispatch(navigateToGlobalLocations());
          return;
        }

        const bookedSupplierCategories = getBookedSupplierCategories(state);
        const supplierTypesByBookingPriority = market.suppliers;
        if (!bookedSupplierCategories) {
          dispatch(
            navigateToSearchResults({
              slug: 'venue',
            }),
          );
          return;
        } else {
          const nextCategoryToBook = orderSupplierTypesByBookingPriority(
            difference(supplierTypesByBookingPriority, bookedSupplierCategories) || [],
          );
          dispatch(
            navigateToSearchResults({
              slug: nextCategoryToBook[0],
            }),
          );
          return;
        }
      }
    };
    asyncAction();
    return noopAction();
  };

export const navigateToSearchResults =
  (
    params: Partial<Omit<TGetSearchUrlWithFiltersParams, 'slug'>> & {
      placeId?: string;
      slug?: Slug;
      country?: CountryCodes;
    },
  ) =>
  ({ getState }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      assertState(state.search.searchLocation, 'initialized', 'search');
      let as = '';

      if (params.area) {
        as = getSearchUrlWithFilters({
          slug: params.slug ?? 'venue',
          area: params.area,
          placeId: params.placeId,
          filters: params.filters ?? {},
          as: true,
          ...params,
          areaContext: params.areaContext
            ? params.areaContext
            : params.country
            ? [params.country]
            : [],
        });
      } else {
        const { location, country } = extractAreaParams(state.search.searchLocation.selected.area);
        const { placeId } = state.search.searchLocation.selected;
        as = getSearchUrlWithFilters({
          slug: params.slug ?? 'venue',
          area: location,
          placeId,
          filters: {},
          as: true,
          ...params,
          areaContext: country
            ? [country]
            : state.search.searchLocation.selected.countryCode
            ? [state.search.searchLocation.selected.countryCode]
            : [],
        });
      }

      await Router.push(as, undefined, { shallow: false }).then(() => window.scrollTo(0, 0));
    };
    asyncAction();

    return noopAction();
  };

export const fetchPopularVenueTypes =
  (market: Market) =>
  ({ getState, dispatch }: IDeps) => {
    const asyncAction = async () => {
      const state = getState();
      const { searchLocation, popularVenueTypes } = state.search;
      assertState(searchLocation, 'initialized', 'search');
      const searchLocationArea = searchLocation.selected.area;

      // If the location is the same, no need to request again
      if (searchLocationArea === popularVenueTypes?.location) return;

      dispatch(
        setPopularVenueTypes({
          list: [],
          isPending: true,
          location: searchLocationArea,
        }),
      );

      const area =
        searchLocation.selected.area || getDefaultSearchArea({ countryCode: market.country });

      const body: Partial<TSearchSupplierTypesRequestBody> = {
        area,
        country: getDefaultSearchArea({ countryCode: market.country }),
        countryCode: market.country,
        locationInfo: true,
        searchParams: ['wedding-venues', area],
        type: 'venue',
      };

      const result = (await authenticatedPOST(ApiEndpoint.search.supplierTypes, {
        body,
      }).catch(() => null)) as TSearchSupplierTypesResponse | null;

      // If there was another search triggered while waiting for the response
      // of the supplierTypes, don't save the data of the initial response
      const anotherSearchLocation = getState().search.searchLocation;
      assertState(anotherSearchLocation, 'initialized', 'search');
      if (!result || searchLocationArea !== anotherSearchLocation.selected.area) return;

      const texts = getPopularVenueTypesTexts();

      const venueTypes = Object.keys(result)
        .reduce<string[]>((acc, venueType) => {
          acc.push(venueType);
          return acc;
        }, [])
        .filter(
          (venueType) =>
            result[venueType] > 0 &&
            !!texts[venueType] &&
            countriesExcludedTypes[market.country]?.has(venueType) !== true &&
            POPULAR_VENUE_TYPES.venueTypesImgs.has(venueType),
        );

      const isValidList = venueTypes.length >= POPULAR_VENUE_TYPES.minCards;

      if (isValidList) {
        venueTypes.sort((a, b) => result[b] - result[a]);
      }

      dispatch(
        setPopularVenueTypes({
          list: isValidList ? venueTypes.slice(0, POPULAR_VENUE_TYPES.maxCards) : [],
          isPending: false,
          location: searchLocationArea,
        }),
      );
    };

    asyncAction();

    return noopAction();
  };
