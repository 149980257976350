import type { PartialRecursive } from '../abstract/_';
import { AbstractCollection } from '../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable } from '../abstract/Document';
import type { IUser } from './Users.types';
import { Tasks } from './Users/Tasks';
import { Weddings } from './Weddings';
import { arrayUnion, runTransaction } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';
import { distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';

@Identifiable
@Timestampable
export class User extends AbstractDocument<IUser> {
  readonly collections = {
    Tasks: new Tasks(this),
  };

  get activeWedding() {
    return this.observe()
      .pipe(
        map((user) => {
          if (Array.isArray(user?.weddings) && user.weddings.length > 0) {
            return Weddings._.getById(user.weddings.pop()).get();
          }

          return null;
        }),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  /**
   * Returns the active wedding.
   */
  async getActiveWedding() {
    return await this.activeWedding.pipe(take(1)).toPromise();
  }

  /**
   * Sets the provided wedding as the active wedding for the user.
   */
  async setActiveWedding(wedding: string) {
    return runTransaction(this.reference.firestore, (transaction) =>
      transaction.get(this.reference).then((snapshot) => {
        const data = snapshot.data();

        let result: IUser['weddings'] = [];

        /**
         * If the user doesn't yet have a reference to the wedding, then the wedding likely also doesn't have a reference to the user.
         */
        if ((data.weddings ?? []).includes(wedding) !== true) {
          transaction.set(Weddings._.getById(wedding).reference, { users: arrayUnion(this.id) }, { merge: true });
        }

        if (data.weddings != null) {
          result = data.weddings.filter((value) => value !== wedding);
        }

        return transaction.update(this.reference, 'weddings', [...result, wedding]);
      }),
    );
  }
}

export class Users extends AbstractCollection<User, IUser> {
  static definitions = {
    _: {} as IUser,
  };

  static path = 'users';

  constructor() {
    super(Users.path, User);
  }

  static new<M extends typeof Users.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IUser> = {
      actions: {
        hasPerformedSearch: false,
        hasViewedCollaboratorPopup: false,
        hasViewedGuestlist: false,
        hasViewedPricingPopup: false,
        hasViewedShortlist: false,
        hasVisitedBooked: false,
      },
    };

    if (key !== '_' && key in Users.definitions) {
      result = (result[key as keyof Omit<typeof Users.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  static get _() {
    return new this();
  }
}
