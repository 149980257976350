import { Experiment } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { useQuery } from '@tanstack/react-query';
import { ApiEndpoint } from 'lib/api/api-endpoint';

const AB_TESTS_CACHE_TIME = toMillis(1, 'hour');

/**
 * Fetches all AB Tests configs from Firestore
 */
export const useABTestsFetch = () => {
  const {
    data: abTests,
    isFetched,
    error,
  } = useQuery<Experiment[]>(
    ['fetch-ab-tests'],
    async () => {
      const response = await fetch(ApiEndpoint.abTests);
      if (!response.ok) {
        return [];
      }
      return response.json();
    },
    {
      cacheTime: AB_TESTS_CACHE_TIME,
      staleTime: AB_TESTS_CACHE_TIME,
      retry: 0, // If fetch fails, just show original variant
    },
  );

  return {
    abTests,
    abTestsLoaded: isFetched,
    error,
  };
};
