import { FelaCSS, TColor, colors } from '@bridebook/ui';

interface IStyles {
  button: FelaCSS;
}

const styles = (
  size: number,
  border?: boolean,
  borderColor?: string,
  boxShadow?: boolean,
  backgroundColor?: TColor,
  hasOnClick?: boolean,
  disabled?: boolean,
): IStyles => ({
  button: {
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: colors[backgroundColor || 'space04'],
    border: `1px solid ${
      border ? borderColor || colors.space40 : colors[backgroundColor || 'space04']
    }`,
    borderRadius: '100%',
    boxShadow: boxShadow ? '0px 0px 3px rgba(0, 0, 0, 0.15)' : 'none',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: disabled ? 'none' : 'all',
    opacity: disabled ? 0.5 : 1,
    cursor: hasOnClick ? 'pointer' : 'inherit',
    padding: 0,
  },
});

export default styles;
