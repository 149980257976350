import { useEffect, useMemo, useRef, useState } from 'react';
import Experiments, { CohortNames } from '@bridebook/toolbox/src/ab-testing/ab-testing';
import { getIsE2E } from '@bridebook/toolbox/src/e2e/get-is-e2e';
import { basicUserIdCookie } from 'app-shared/lib/ab-tests/basic-user-id-cookie';
import { useABTestsLoggedIn } from 'lib/ab-tests/hooks/use-ab-tests-logged-in';
import { getIsTestActivated } from 'lib/ab-tests/selectors';
import { participatedInABTestAnalytics, setABTestVariant } from 'lib/ui/actions';
import { loggedOutOnlyPages } from 'lib/url-helper';
import { getCurrentUserId } from 'lib/users/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import { usePathWithoutMarket } from 'lib/utils/url';

const SALT = '3b3652a7ca980aa1f7c9a31b44b230f7';

/**
 * Activates an AB Test
 *
 * @param testId - id of the test from firestore
 * @param triggerCondition (optional) - test will run only if this is undefined or true
 * @param triggerForLoggedOutUsers (optional) - flag to trigger the test for logged out users (eg /signup page)
 */
export const useABTestIntegration = ({
  testId,
  triggerCondition = true,
  triggerForLoggedOutUsers,
}: {
  testId: string;
  triggerCondition?: boolean;
  triggerForLoggedOutUsers?: boolean;
}) => {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(getCurrentUserId);
  const path = usePathWithoutMarket();

  const [userId, setUserId] = useState(loggedInUserId);

  const isOnLoggedOutPage = useMemo(
    () => loggedOutOnlyPages.some((page) => path.includes(page)),
    [path],
  );

  // The first client-side render has to match the server, so we can set a user id
  // only in the second render using `useEffect`
  useEffect(() => {
    if (userId) return;

    // Make sure we are not setting the user id on logged out pages (e.g. can happen if we manually open /signup when logged in)
    const shouldSetLoggedInUserId = loggedInUserId && !isOnLoggedOutPage;

    // Only for pages that use full layout but are still logged out pages. Test for them should have triggerForLoggedOutUsers flag set to true
    const shouldSetLoggedOutUserId = isOnLoggedOutPage && triggerForLoggedOutUsers;

    if (shouldSetLoggedInUserId) {
      setUserId(loggedInUserId);
      return;
    }
    if (shouldSetLoggedOutUserId) {
      setUserId(basicUserIdCookie.set());
      return;
    }
  }, [isOnLoggedOutPage, loggedInUserId, triggerForLoggedOutUsers, userId]);

  const isTestActivated = useSelector((state) => getIsTestActivated(state, testId));
  const { abTests } = useABTestsLoggedIn();
  const abTest = abTests?.find((test) => test.id === testId);
  const assignedVariant = useRef<CohortNames | undefined>();

  useEffect(() => {
    if (!abTest) return;
    // Return if running in test mode
    if (getIsE2E()) return;
    // Return if the test has been activated before.
    if (isTestActivated) return;
    // Return if trigger conditions are not met
    if (!triggerCondition) return;
    if (!userId) return;

    const experiments = new Experiments(
      {
        version: '1.0',
        experiments: [abTest],
        salt: SALT,
      },
      userId,
      { id: userId },
    );
    assignedVariant.current = experiments.getCohort(testId) as CohortNames;

    // Activate the test
    dispatch(setABTestVariant({ id: testId, variant: assignedVariant.current }));

    // Send analytics to mixpanel
    dispatch(participatedInABTestAnalytics({ abTest, variant: assignedVariant.current }));
  }, [abTest, dispatch, isTestActivated, testId, triggerCondition, userId]);
  return {
    variant: assignedVariant.current,
  };
};
